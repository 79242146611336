.banners{
	width: 100%;
	max-width: 1920px;
	margin-right: auto;
	margin-left: auto;
	position: relative;
	margin-bottom: 40px;

	.carousel-indicators{
		position: absolute;
		margin-bottom: 0;
		bottom: 20px;
		right: auto;
		left: 0;

		li{
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background-color: #fff;
			border: 4px solid #fff;
			opacity: 1;
			transition: all 0.2s linear;

			&.active{
				background-color: transparent;
			}
		}
		li + li{
			margin-left: 10px;
		}
	}
	// .carousel-indicators

	.banner-noticias{
		border-left:6px solid #FFF;
		border-right:6px solid #FFF;
		.box-banner{
			font-family: $mts-medium;
			font-size: 14px;
			line-height: 18px;

			.box-header{
				display: inline-flex;
				align-items: center;
				text-transform: uppercase;
				margin-bottom: 15px;

				.cubo-cat{
					margin-right: 10px;
				}
				span{
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}
			}
			.box-titulo{
				font-size: 15px;
				font-family: $mts-bold;
				line-height: 19px;
				@include line-clamp(2);
			}
			.box-desc{
				margin: 10px 0 15px 0;
				@include line-clamp(3);
			}
			.botao{
				i.fas{
					margin-left: 5px;
				}
			}
		}
	}
	// .banner-noticias


	@include media-breakpoint-only(xl) {
		.carousel{
			position: relative;
			min-width: 1920px;
			left: 50%;
			transform: translateX(-50%);
			.carousel-inner{
				z-index: 2;
				.carousel-item{
					position: relative;
				}
			}
		}
		.carousel-indicators{
			margin-left: calc(calc(100% - 1110px)/2);
		}
		.banner-noticias{
			position: absolute;
			right: 0;
			margin-right: calc(calc(100% - 1110px)/2);
			z-index: 5;
			top: 0;
			width: 100%;
			max-width: 350px;
			height: 100%;
			background-color: rgba(0,0,0,0.6);
			padding: 30px 0px 30px 0px;

			.container{
				padding: 0;
			}

			.box-banner{
				color: #fff;
				padding: 0px 30px 0px 40px;

				.botao{
					text-align: right;
				}
			}
			.box-banner + .box-banner{
				margin-top: 20px;
				padding-top: 20px;
				border-top: 5px solid #fff;
			}
		}
	}
	@include media-breakpoint-up(lg) {
		.banner-noticias{
			.box-banner{
				.box-titulo{
					height: 38px;
				}
				.box-desc{
					height: 54px;
				}
			}
		}
	}
	@include media-breakpoint-down(lg) {
		.carousel-indicators{
			margin: 0 auto;
			left: 50%;
			transform: translateX(-50%);
			bottom: 10px;
		}
		.banner-noticias{
			padding: 15px 0;
			.box-banner{
				color: #000;
			}
		}
	}
	@include media-breakpoint-only(lg) {
		.banner-noticias{
			.container{
				display: flex;
				padding: 0 15px;
				.box-banner + .box-banner{
					margin-left: 15px;
				}
				.box-banner{
					max-width: 300px;
					width: 100%;
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
		.banner-noticias{
			.box-banner + .box-banner{
				margin-top: 15px;
			}
			.box-banner{
				.box-titulo{
					max-height: 38px;
				}
				.box-desc{
					max-height: 54px;
				}
			}
		}
	}
}
//banners

.header-section{
	margin-bottom: 30px;

	.titulo-box{
		margin-bottom: 15px;
	}

	@include media-breakpoint-only(xl) {
		.desc{
			padding-left: 50px;
		}
	}
	@include media-breakpoint-up(lg) {
		.btn-padrao{
			margin-left: auto;
		}
	}
	@include media-breakpoint-down(md) {
		.col-lg-4{
			display: none;
		}
	}
}
// .header-section

.box-anuncio{
	margin-bottom: 40px;
}

.home-produtos{
	padding-bottom: 40px;

	.owl-carousel{
		.owl-nav{
			width: 100%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 2;

			.owl-prev,
			.owl-next{
				position: absolute;
				top: 0;
				transform: translateY(-50%);
			}
			.owl-prev{
				left: -5px;
			}
			.owl-next{
				right: -5px;
			}
		}
	}


	@include media-breakpoint-up(lg) {
		.btn-padrao.down-md{
			display: none;
		}
	}
	@include media-breakpoint-down(md) {
		.btn-padrao.down-md{
			margin-left: auto;
			margin-right: auto;
			margin-top: 30px;
		}
	}
	@include media-breakpoint-only(xs) {
		.owl-carousel{
			.owl-nav{
				max-width: 350px;
			}
		}
	}
}
// .home-produtos

.home-noticias{
	padding-bottom: 40px;

	.box-noticia-destaque{
		color: #000;
		.img{
			margin-bottom: 15px;
			position: relative;

			.box-legenda{
				position: absolute;
				top: 25px;
				left: 0;
				z-index: 1;
				background-color: #ededed;
				display: flex;
				align-items: center;
				padding: 10px 10px 10px 0;

				.cubo-cat{
					margin-right: 10px;
				}
				span{
					font-size: 14px;
					text-transform: uppercase;
					color: #000;
					font-family: $mts-medium;
				}
			}
		}
		.nome{
			font-family: $mts-bold;
			margin-bottom: 10px;
		}
		.data{
			display: flex;
			align-items: center;
			font-size: 14px;
			line-height: 1;
			font-family: $mts-medium;
			margin-bottom: 10px;

			svg{
				font-size: 20px;
				line-height: 1;
				margin-right: 5px;
			}
		}
		.texto{
			font-size: 14px;
			line-height: 18px;
			margin-bottom: 5px;
		}
		.botao{
			text-align: right;
			font-size: 14px;

			i.fas{
				margin-left: 5px;
			}
		}
	}
	// .box-noticia-destaque

	.box-noticia-aside{
		color: #000;
		margin-bottom: 15px;

		.box-header{
			display: flex;
			align-items: center;
			margin-bottom: 10px;

			.cubo-cat{
				margin-right: 10px;
				transition: all 0.3s linear;
			}
			span{
				font-size: 14px;
				text-transform: uppercase;
				font-family: $mts-medium;
				transition: all 0.3s linear;
			}
		}
		.nome{
			font-size: 15px;
			line-height: 19px;
			font-family: $mts-bold;
			margin-bottom: 5px;
		}
		.texto{
			font-size: 14px;
			line-height: 18px;
			margin-bottom: 5px;
		}
		.botao{
			text-align: right;
			font-size: 14px;

			i.fas{
				margin-left: 5px;
			}
		}

		&:hover{
			.box-header{
				.cubo-cat{
					background-color: $vermelho;
					color: #fff;
				}
				span{
					color: $vermelho;
				}
			}
		}
	}
	// .box-noticia-aside

	.anuncio-aside{
		img{
			min-height: 400px;
		}
	}

	@include media-breakpoint-up(lg) {
		.box-noticia-destaque{
			.nome{
				font-size: 20px;
				line-height: 22px;
			}
		}
		.box-noticia-aside{
			.nome{
				height: 38px;
				@include line-clamp(2);
			}
		}
		.btn-padrao.down-md{
			display: none;
		}
	}
	@include media-breakpoint-down(md) {
		.btn-padrao.down-md{
			margin-left: auto;
			margin-right: auto;
			margin-top: 15px;
		}
		.box-noticia-destaque{
			.nome{
				font-size: 16px;
				line-height: 18px;
			}
		}
		aside.col-xl-3{
			margin-top: 20px;
		}
	}
}
// .home-noticias

.home-colunistas{
	background-color: #000;
	max-width: (map-get($container-max-widths, xl) - 30px);
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;

	@include media-breakpoint-up(xl) {
		.container{
			// margin-left:-15px;
		}
	}

	.header-colunista{
		display: flex;
		align-items: center;
		position: relative;
		z-index: 1;

		.circle{
			width: 30px;
			min-width: 30px;
			height: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			background-color: #000;
			color: $amarelo;
			position: relative;
			z-index: 1;
			margin-right: 5px;
		}

		.titulo-colunista{
			position: relative;
			font-size: 24px;
			color: #fff;
			line-height: 26px;
			text-transform: uppercase;
			font-family: $mts-medium;
			background-color: #000;
			padding: 9px 10px 5px 10px;

			&:before,
			&:after{
				content: '';
				position: absolute;
				top: 0;
				width: 100vw;
				height: 100%;
				background-color: $amarelo;
				z-index: -1;
			}
			&:before{
				right: 100%;
			}
			&:after{
				left: 100%;
			}
		}
	}
	// .header-colunista

	.segura-carousel{
		padding: 40px 0;

		.box-colunista{
			text-align: center;
			color: #fff;
			font-size: 14px;
			line-height: 18px;
			font-family: $mts-medium;

			.img{
				overflow: hidden;
				width: 100%;
				max-width: 160px;
				border-radius: 50%;
				margin: 0 auto 10px auto;
			}

			.nome{
				text-transform: uppercase;
				font-size: 14px;
				line-height: 18px;
			}
			.texto{
				font-family: $mts-bold;
				font-size: 14px;
				line-height: 18px;
				margin: 5px 0;
				height: 36px;
				@include line-clamp(2);
			}
			.botao{
				i.fas{
					margin-left: 5px;
				}
			}
		}

		.owl-carousel{
			.owl-nav{
				width: 100%;
				position: absolute;
				top: 33%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 2;

				.owl-prev,
				.owl-next{
					position: absolute;
					top: 0;
					transform: translateY(-50%);
				}
				.owl-prev{
					left: -5px;
				}
				.owl-next{
					right: -5px;
				}
			}
		}
	}
}
// .home-colunistas

.home-backstage{
	padding: 40px 0;

	@include media-breakpoint-only(xl) {
		.grid-container{
			grid-template-columns: 540px 255px 255px;
			grid-gap: 30px;
		}
	}
	@include media-breakpoint-only(lg) {
		.grid-container{
			grid-template-columns: 450px 210px 210px;
			grid-gap: 30px;
		}
	}
	@include media-breakpoint-up(lg) {
		.btn-padrao.down-md{
			display: none;
		}
		.grid-container{
			display: grid;
			grid-template-rows: auto auto;

			.card-padrao:first-child{
				grid-row: span 2;
			}
		}
	}
	@include media-breakpoint-down(md) {
		.btn-padrao.down-md{
			margin-left: auto;
			margin-right: auto;
			margin-top: 30px;
		}
		.card-padrao{
			margin-bottom: 20px;
		}
	}
}
// .home-backstage

.home-ultimas{
	padding-bottom: 40px;

	.owl-carousel{
		.owl-nav{
			width: 100%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 2;

			.owl-prev,
			.owl-next{
				position: absolute;
				top: 0;
				transform: translateY(-50%);
			}
			.owl-prev{
				left: -5px;
			}
			.owl-next{
				right: -5px;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		padding-bottom: 40px;

		.btn-padrao.down-md{
			display: none;
		}
	}
	@include media-breakpoint-down(md) {
		.btn-padrao.down-md{
			margin-left: auto;
			margin-right: auto;
			margin-top: 30px;
		}
	}
	@include media-breakpoint-only(xs) {
		.owl-carousel{
			.owl-nav{
				max-width: 350px;
			}
		}
	}
}
// .home-ultimas
