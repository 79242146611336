.topo{

	ul{
		list-style-type: none;
		margin-bottom: 0;
		padding-left: 0;
	}
	.main-busca{
		.group{
			position: relative;

			.form-control{
				background-color: $cinza;
				height: 48px;
				border-radius: 8px;
				border: none;
				color: #fff;
				font-size: 12px;
				font-family: $mts-medium;

				&::placeholder{
					color: #fff;
				}
			}
			button{
				position: absolute;
				border: none;
				outline: none;
				background-color: $cinza;
				color: #fff;
				top: 50%;
				transform: translateY(-50%);
				right: 10px;
				padding: 10px;
			}
		}
	}
	// .main-busca

	@include media-breakpoint-only(xl) {
		.bar-topo-mbl,
		.mbl-controls{
			display: none;
		}
		.main-topo{
			display: block;
			background-color: #010606;
			padding: 25px 0;

			.container{
				display: flex;
				align-items: center;
				justify-content: space-between;

				.main-busca{
					min-width: 280px;
				}
			}
		}
		// .main-topo
		.main-navigation{
			background: linear-gradient(to bottom, rgba(51,51,51,1) 0%,rgba(34,34,34,1) 100%);

			.menu{
				display: flex;
				justify-content: space-between;

				li{
					display: flex;

					&.active a{
						color: $amarelo;

						&:before{
							width: 100%;
						}
					}

					a{
						display: flex;
						font-size: 14px;
						color: #fff;
						line-height: 1;
						padding: 18px 5px;
						position: relative;
						transition: all 0.3s ease-out;

						&:before{
							content: '';
							position: absolute;
							top: 50%;
							left: 0;
							transform: translateY(-50%);
							width: 0%;
							height: 2px;
							background-color: $vermelho;
							transition: all 0.3s ease-out;
						}

						&:hover{
							color: $amarelo;

							&:before{
								width: 100%;
							}
						}
					}
				}
			}
		}
		// .main-navigation
	}
	//DESKTOP

	@include media-breakpoint-down(lg) {
		.bar-topo-mbl{
			background: linear-gradient(to bottom, rgba(51,51,51,1) 0%,rgba(34,34,34,1) 100%);

			.anuncio-topo-bar{
				text-align: center;
				.anuncio-topo{
					padding: 10px 15px 0 15px;
				}
			}
			.container{
				text-align: right;
				line-height: 16px;
				padding: 5px 15px;

				.btn-collapse{
					color: #fff;
					font-size: 16px;
					cursor: pointer;
					padding: 10px 0;
					display: inline-block;

					i.fas{
						margin-left: 5px;
						transition: all 0.2s linear;
					}
				}
				.btn-collapse[aria-expanded="true"] {
					i.fas{
						transform: rotate(180deg);
					}
				}
			}
		}
		.mbl-controls{
			background-color: #010606;
			padding: 10px 0;

			.container{
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-wrap: wrap;
			}
			.logo{
				margin: 0 5px;
			}
			.btn-toggle{
				color: #fff;
				cursor: pointer;

				&:hover{
					color: #fff;
				}
			}
			.botao:first-child{
				.btn-toggle{
					padding: 10px 15px 10px 5px;
				}
			}
			.botao:last-child{
				.btn-toggle{
					padding: 10px 5px 10px 15px;
				}
			}
		}
		//.mbl-controls
		.main-topo{
			.logo,
			.anuncio-topo{
				display: none;
			}
			.main-busca{
				position: fixed;
				top: -100px;
				left: 50%;
				transform: translateX(-50%);
				z-index: 1000;
				width: 100%;
				max-width: 400px;
				padding: 15px;
				transition: all 0.4s ease-out;

				&.active{
					top: 100px;
				}
			}
		}
		.main-navigation{
			position: fixed;
			top: 0;
			left: -250px;
			width: 250px;
			height: 100%;
			overflow-y: auto;
			z-index: 1000;
			background: linear-gradient(to bottom, rgba(51,51,51,1) 0%,rgba(34,34,34,1) 100%);
			transition: all 0.4s ease-out;
			border-right: 1px solid #000;

			&.active{
				left: 0;
			}
			.container{
				padding: 0;
			}
			.menu{
				position: relative;

				&:before{
					content: 'MENU';
					position: relative;
					display: block;
					text-align: center;
					padding: 10px;
					font-family: $mts-bold;
					background-color: $amarelo;
					color: $vermelho;
					font-size: 18px;
					line-height: 1;
				}

				li{
					&.active{
						background-color: #000;
					}
					a{
						display: block;
						padding: 14px 5px 14px 10px;
						color: #fff;
						font-size: 14px;
						line-height: 16px;

						&:hover{
							background-color: #000;
						}
					}
				}
			}
		}
		// .main-navigation
	}
	//DOWN-LG

	@include media-breakpoint-down(sm) {
		.mbl-controls{
			.logo{
				width: 200px;
			}
		}
	}
	//DOWN-sm
}