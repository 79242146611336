.form-estilos{

	.form-group{
		margin-bottom: 12px;
	}
	input.form-control{
		height: 48px;
	}
	.form-control{
		background-color: #eee;
		color: #656565;
		font-size: 13px;
		border-radius: 0;
		border: none;

		&::placeholder{
			color: #656565;
		}
	}
	.btn-padrao{
		outline: none;
		border: none;
		margin-top: 25px;
	}

}