@font-face {
    font-family: 'baloo_bhaijaanregular';
    font-display: auto;
    src: url('../fonts/baloobhaijaan-regular/baloobhaijaan-regular-webfont.eot');
    src: url('../fonts/baloobhaijaan-regular/baloobhaijaan-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/baloobhaijaan-regular/baloobhaijaan-regular-webfont.woff2') format('woff2'),
         url('../fonts/baloobhaijaan-regular/baloobhaijaan-regular-webfont.woff') format('woff'),
         url('../fonts/baloobhaijaan-regular/baloobhaijaan-regular-webfont.ttf') format('truetype'),
         url('../fonts/baloobhaijaan-regular/baloobhaijaan-regular-webfont.svg#baloo_bhaijaanregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratbold';
    font-display: auto;
    src: url('../fonts/montserrat-bold/montserrat-bold-webfont.eot');
    src: url('../fonts/montserrat-bold/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-bold/montserrat-bold-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-bold/montserrat-bold-webfont.woff') format('woff'),
         url('../fonts/montserrat-bold/montserrat-bold-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-bold/montserrat-bold-webfont.svg#montserratbold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratmedium';
    font-display: auto;
    src: url('../fonts/montserrat-medium/montserrat-medium-webfont.eot');
    src: url('../fonts/montserrat-medium/montserrat-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-medium/montserrat-medium-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-medium/montserrat-medium-webfont.woff') format('woff'),
         url('../fonts/montserrat-medium/montserrat-medium-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-medium/montserrat-medium-webfont.svg#montserratmedium') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratregular';
    font-display: auto;
    src: url('../fonts/montserrat-regular/montserrat-regular-webfont.eot');
    src: url('../fonts/montserrat-regular/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-regular/montserrat-regular-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-regular/montserrat-regular-webfont.woff') format('woff'),
         url('../fonts/montserrat-regular/montserrat-regular-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-regular/montserrat-regular-webfont.svg#montserratregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratsemibold';
    font-display: auto;
    src: url('../fonts/montserrat-semibold/montserrat-semibold-webfont.eot');
    src: url('../fonts/montserrat-semibold/montserrat-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-semibold/montserrat-semibold-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-semibold/montserrat-semibold-webfont.woff') format('woff'),
         url('../fonts/montserrat-semibold/montserrat-semibold-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-semibold/montserrat-semibold-webfont.svg#montserratsemibold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratblack';
    font-display: auto;
    src: url('../fonts/montserrat-black/montserrat-black-webfont.eot');
    src: url('../fonts/montserrat-black/montserrat-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-black/montserrat-black-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-black/montserrat-black-webfont.woff') format('woff'),
         url('../fonts/montserrat-black/montserrat-black-webfont.ttf') format('truetype'),
         url('../fonts/montserrat-black/montserrat-black-webfont.svg#montserratblack') format('svg');
    font-weight: normal;
    font-style: normal;
}