.internas{
	padding-top: 50px;

	.box-anuncio{
		margin-bottom: 30px;
	}

	.header-chamada{
		margin-bottom: 40px;

		.titulo-box{
			margin-bottom: 15px;
		}
	}
	.box-aside-categorias{
		background-color: #ededed;
		margin-bottom: 20px;

		.titulo-box{
			display: flex;
			margin-bottom: 0;
		}

		.categorias-lista{
			list-style-type: none;
			margin-bottom: 0;
			padding: 10px;
			font-size: 13px;
			line-height: 15px;
			color: #000;
			text-align: center;
			font-family: $mts-medium;

			li + li{
				margin-top: 5px;
			}

			a:hover{
				text-decoration: underline;
			}
		}
	}

	.anuncio-aside{
		margin-bottom: 20px;
	}

	.box-ultima-noticia-aside + .box-ultima-noticia-aside{
		padding-top: 20px;
		border-top: 2px solid #ddd;
	}

	.box-ultima-noticia-aside{
		color: #000;
		font-size: 14px;
		line-height: 16px;
		margin-bottom: 20px;

		.titulo-box{
			background-color: #fff;
			color: #000;
			font-size: 14px;
			position: relative;
			cursor: pointer;
			margin-bottom: 10px;
			transition: all 0.2s linear;

			.cubo-cat{
				width: 30px;
				min-width: 30px;
				height: 30px;
				font-size: 14px;
				transition: all 0.2s linear;
			}

			&:after{
				content: '+';
				position: relative;
				display: block;
				margin: auto 0;
				font-size: 18px;
			}
		}
		.titulo-box[aria-expanded="true"] {
			color: $vermelho;
			.cubo-cat{
				background-color: $vermelho;
				color: #fff;
			}
			&:after{
				content: '-';
			}
		}
		.nome{
			font-size: 15px;
			line-height: 17px;
			font-family: $mts-bold;
			margin-bottom: 5px;
			max-height: 34px;
			@include line-clamp(2);
		}
		.botao{
			margin-top: 10px;
			font-family: $mts-medium;
			text-align: right;

			i.fas{
				margin-left: 5px;
			}
		}
	}
	// .box-ultima-noticia-aside

	.compartilhe{
		list-style-type: none;
		margin-bottom: 0;
		padding-left: 0;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		font-size: 14px;
		color: #000;
		font-family: $mts-bold;
		margin-top: 20px;

		a{
			width: 26px;
			min-width: 26px;
			height: 26px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			line-height: 1;
			color: #fff;
			border-radius: 50%;
			margin-left: 10px;

			&.fa-facebook-f{
				background-color: #4064ad;
			}
			&.fa-twitter{
				background-color: #1fc6f7;
			}
			&.fa-envelope{
				background-color: #b6b6b6;
			}
			&.fa-whatsapp{
				background-color: #3fbf50;
			}
		}
	}

	.segura-comentarios{
		.subtitulo{
			font-size: 26px;
			line-height: 1;
			font-family: $mts-black;
			color: #000;
			text-transform: uppercase;
			margin-bottom: 30px;
		}
		.listagem-comentarios{
			.item-comentario + .item-comentario{
				margin-top: 30px;
			}
			.item-comentario{
				font-size: 14px;
				line-height: 16px;
				color: #000;
				padding-top: 30px;
				border-top: 1px solid #e1e1e1;

				p + p{
					margin-top: 10px;
				}

				.box-resposta{
					position: relative;
					padding-left: 32px;
					padding-top: 5px;
					margin-top: 10px;

					&:before{
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 26px;
						height: 23px;
						background-position: center center;
						background-size: 100% 100%;
						background-repeat: no-repeat;
						background-image: url('../images/icon-resposta.png');
					}
				}
			}
		}
		.form-estilos{
			margin-top: 25px;
		}
	}

	.box-lista-produtos{
		margin-bottom: 20px;

		&.tipo-colunista{
			.img{
				border-radius: 50%;
				overflow: hidden;
			}
		}

		.subtitulo{
			font-size: 17px;
			line-height: 19px;
			font-family: $mts-bold;
			color: #000;
			margin-bottom: 15px;
			text-transform: uppercase;
		}
		.item-produto{
			display: flex;
			align-items: center;

			.texto{
				font-size: 14px;
				line-height: 18px;
				color: #3F3F43;
				font-family: $mts-medium;
			}
			.img{
				min-width: 60px;
				max-width: 60px;
				margin-left: 10px;
			}
		}
		.item-produto + .item-produto{
			margin-top: 20px;
			padding-top: 20px;
			border-top: 1px solid #aaa;
		}
	}
	// .box-lista-produtos

	.fim-colunista{
		display: flex;
		align-items: center;
		margin-top: 40px;

		.img{
			min-width: 84px;
			max-width: 84px;
			margin-right: 10px;
			border-radius: 50%;
			overflow: hidden;
		}
		.texto{
			font-size: 14px;
			line-height: 18px;
			color: #3F3F43;
			font-family: $mts-medium;
			max-height: 54px;
			@include line-clamp(3);
		}
		.nome{
			font-family: $mts-bold;
			font-size: 15px;
			line-height: 17px;
			text-transform: uppercase;
			color: #000;
			margin-top: 5px;
		}
	}
	// .fim-colunista

	@include media-breakpoint-only(xl) {
		.header-chamada{
			.desc{
				padding-left: 50px;
			}
		}
	}
	@include media-breakpoint-up(lg) {
		padding-bottom: 150px;

		.box-ultima-noticia-aside{
			.titulo-box{
				display: flex;
				justify-content: space-between;
			}
		}
		.segura-comentarios{
			.listagem-comentarios{
				max-height: 500px;
				overflow-x: auto;
			}
		}
	}
	@include media-breakpoint-down(md) {
		padding-bottom: 50px;

		.box-aside-categorias{
			.categorias-lista{
				font-size: 16px;
				line-height: 18px;
			}
		}

		.box-ultima-noticia-aside{
			.titulo-box{
				&:after{
					margin-left: 10px;
				}
			}
		}

		aside.col-xl-3.col-lg-4{
			margin-top: 30px;
		}

		.segura-comentarios{
			.col-lg-6 + .col-lg-6{
				margin-top: 30px;
			}
			.btn-padrao{
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}
// .internas

.pag-detalhes{
	.titulo-box{
		margin-bottom: 30px;
	}
	.header-detalhes{
		border-top: 14px solid #404043;
		padding-top: 20px;
		margin-bottom: 30px;

		.titulo-pag{
			font-family: $mts-bold;
			color: #000;
			line-height: 1.2;
			margin-bottom: 10px;
		}
		.data,
		.atualizado{
			font-size: 13px;
			color: #404043;
		}
	}
	.conteudo-detalhes{
		margin-bottom: 30px;
	}
	.btn-voltar{
		margin: 30px auto 0 auto;
	}
	.iframe-video{
		padding-bottom: percentage(720/1280);
		margin-bottom: 20px;
	}

	@include media-breakpoint-only(xl)  {
		.header-detalhes{
			.titulo-pag{
				font-size: 34px;
			}
		}
	}
	@include media-breakpoint-only(lg)  {
		.header-detalhes{
			.titulo-pag{
				font-size: 30px;
			}
		}
	}
	@include media-breakpoint-only(md)  {
		.header-detalhes{
			.titulo-pag{
				font-size: 26px;
			}
		}
	}
	@include media-breakpoint-down(sm)  {
		.header-detalhes{
			.titulo-pag{
				font-size: 20px;
			}
		}
	}
}
// .pag-detalhes

.pag-formularios{

	.header-chamada{
		margin-bottom: 30px;
	}

	.form-estilos{
		width: 100%;
		max-width: 990px;
		margin: 0 auto;

		.btn-padrao{
			margin-left: auto;
			margin-right: auto;
		}

		@include media-breakpoint-up(sm) {
			.col-sm-6:nth-child(odd) {
				padding-right: 5px;
			}
			.col-sm-6:nth-child(even) {
				padding-left: 5px;
			}
		}
	}
}
// .pag-formularios

.pag-edicoes{
	.col-lg-3{
		margin-bottom: 30px;
	}
	.chamada-paginacao{
		text-align: center;
		font-size: 16px;
		color: #000;
		font-family: $mts-medium;
		margin-bottom: 5px;
	}
	.paginacao{
		margin-top: 0;
	}

	@include media-breakpoint-down(sm) {
		.col-lg-3{
			margin-bottom: 10px;
		}
		.col-lg-3:nth-child(odd) {
			padding-right: 5px;
		}
		.col-lg-3:nth-child(even) {
			padding-left: 5px;
		}
	}
}

.pag-qualificacao{
	.paginacao{
		margin-top: 0;
	}
}
// .pag-qualificacao

.pag-listagem{
	.segura-carousel{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
	}

	.box-colunista{
		width: 100%;
		max-width: 160px;
		margin: 15px;
		text-align: center;

		.img,
		img{
			border-radius: 50%;
		}

		.img{
			margin-bottom: 15px;
		}

		.nome{
			font-size: 18px;
		}
	}
}
