.rodape{
	background-color: #010606;
	color: #fff;

	@include media-breakpoint-up (lg){
		margin-top: 100px;
	}

	.icon__parler{
		display: block;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		mask-image: url('../images/parler.png');
		mask-repeat: no-repeat;
		mask-size: 30px 30px;
		mask-position: center;
		background-color: #000;
	}

	.fim-rodape{
		font-family: $baloo;
		font-size: 13px;
		line-height: 17px;

		.autorais{
			margin-top: 10px;
		}
		.gv8{
			display: inline-flex;
			align-items: center;
			svg{
				font-size: 40px;
				margin-left: 10px;
			}
		}
	}

	.informacoes-rodape{
		background-color: $vermelho;
		overflow: hidden;
		padding: 30px 40px 30px 65px;
		position: relative;
		font-family: $mts-medium;
		font-size: 14px;
		line-height: 16px;
		z-index: 2;

		.cubo-cat{
			width: 35px;
			min-width: 35px;
			height: 35px;
			font-size: 28px;
			position: absolute;
			top: 30px;
			left: 15px;
			z-index: 1;
		}

		.titulo{
			font-size: 24px;
			line-height: 26px;
			text-transform: uppercase;
			padding-top: 5px;
		}
		.subtitulo{
			font-size: 14px;
			line-height: 16px;
			text-transform: uppercase;
			margin-top: 15px;
			margin-bottom: 5px;
		}
		.item{
			a:hover{
				text-decoration: underline;
			}
		}
		.redes-sociais{
			list-style-type: none;
			margin-bottom: 0;
			padding-left: 0;
			display: flex;
			align-items: center;
			margin-top: 10px;

			li + li{
				margin-left: 10px;
			}
			a{
				width: 30px;
				height: 30px;
				border-radius: 50%;
				background-color: #000;
				color: $vermelho;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 18px;

				&:hover{
					background-color: $amarelo;
				}
			}
		}
		.form-newsletter{
			margin-top: 30px;

			.titulo{
				padding: 0;
				margin-bottom: 10px;
			}

			.input-group{
				position: relative;

				&:after{
					content: '';
					position: absolute;
					top: 0;
					right: 100%;
					background-color: #010606;
					height: 100%;
					width: 100%;
					z-index: 2;
				}

				.form-control{
					height: 54px;
					background-color: #010606;
					color: #fff;
					font-style: italic;
					font-size: 14px;
					border: none;

					&::placeholder{
						color: #fff;
					}
					&:focus{
						box-shadow: none;
					}
				}
				button{
					width: 42px;
					border: none;
					outline: none;
					background-color: $amarelo;
					color: #000;
					font-size: 24px;
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.informacoes-rodape{
			margin-top: -100px;
		}
		.fim-rodape{
			align-self: flex-end;
			padding-bottom: 50px;
		}
	}
	@include media-breakpoint-down(md) {
		.informacoes-rodape{
			order: 1;
		}
		.fim-rodape{
			order: 2;
			padding: 30px;
			text-align: center;
		}
	}
	@include media-breakpoint-only(xs) {
		.informacoes-rodape{
			padding-right: 15px;
		}
	}
}
// .rodape
