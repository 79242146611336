body{
	font-family: $mts-regular;
}
body,
html{
	min-height: 100vh;
	width: 100%;
}
#app{
	overflow: hidden;
}
a{
	color: inherit;

	&:hover,
	&:focus{
		color: inherit;
		text-decoration: none;
	}
}
img[style] {
	max-width: 100%;
	height: auto;
}
p, h1, h2, h3, h4, h5, h6, figure{
	margin-bottom: 0;
}
textarea{
	resize: none;
}
.bg-toggle{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255,255,255,0.6);
	display: none;
	z-index: 999;
}
.cubo-cat{
	width: 30px;
	min-width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $amarelo;
	text-transform: uppercase;
	line-height: 1;
	font-family: $mts-medium;
	color: #000;
	font-size: 14px;
}
.titulo-box{
	display: inline-flex;
	align-items: center;
	background-color: #fff;
	background-color: #000;
	font-size: 24px;
	color: #fff;
	font-family: $mts-medium;
	line-height: 1;
	text-transform: uppercase;
	padding-right: 5px;

	.cubo-cat{
		width: 40px;
		min-width: 40px;
		height: 40px;
		font-size: 18px;
		margin-right: 10px;
	}
}
.desc{
	font-size: 14px;
	line-height: 18px;
	color: #414143;

	&:after{
		content: '';
		display: block;
		clear: both;
	}

	img{
		max-width: 100%;
		height: auto !important;
	}
	iframe{
		max-width: 100%;
	}
	a:hover{
		text-decoration: underline;
	}

	&.editor{
		p + p{
			margin-top: 1em;
		}
	}

}
.owl-carousel{
	.owl-nav{
		.owl-prev,
		.owl-next{
			width: 30px;
			height: 30px;
			background-color: #414143;
			color: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: all 0.3s linear;

			&:hover{
				background-color: $vermelho;
				color: #fff;
			}
		}
	}
}


.card-padrao{
	position: relative;
	overflow: hidden;
	width: 100%;
	margin-left: auto;
	margin-right: auto;

	&.tipo-um{
		max-width: 350px;

		.box-header{
			display: flex;
			align-items: center;
			position: absolute;
			top: 20px;
			left: 20px;
			z-index: 2;

			.cubo-cat{
				transition: all 0.3s ease-out;
			}

			span{
				margin-left: 10px;
				color: #fff;
				font-size: 14px;
				font-family: $mts-medium;
				position: relative;
			}
		}
	}

	&.tipo-dois{
		max-width: 540px;

		.box-header{
			position: absolute;
			top: 10px;
			left: 0;
			z-index: 1;
			background-color: #ededed;
			display: flex;
			align-items: center;
			padding: 10px 10px 10px 0;

			.cubo-cat{
				margin-right: 10px;
			}
			span{
				font-size: 14px;
				text-transform: uppercase;
				color: #000;
				font-family: $mts-medium;
			}
		}
	}

	.link{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 4;
	}
	.img{
		position: relative;

		&:before{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			background-color: rgba(0,0,0,0.65);
			opacity: 0;
			transition: all 0.3s ease-out;
		}
	}
	.titulo-card{
		font-size: 14px;
		line-height: 18px;
		font-family: $mts-semibold;
		height: 36px;
		@include line-clamp(2);
	}
	.botao{
		font-size: 14px;
		font-family: $mts-medium;
		transition: all 0.3s ease-out;

		i.fas{
			margin-left: 10px;
		}
	}

	&:hover{
		.img:before{
			opacity: 1;
		}
	}

	@include media-breakpoint-up(lg) {
		&.tipo-um{
			.box-header{
				span{
					right: -30px;
					opacity: 0;
					transition: all 0.3s ease-out;
				}
			}
			.titulo-card{
				&:before{
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 0;
					height: 100%;
					background-color: #fff;
					transition: all 0.3s ease-out;
				}
			}

			&:hover{
				.box-header{
					.cubo-cat{
						background-color: $vermelho;
						color: #fff;
					}
					span{
						opacity: 1;
						right: 0;
					}
				}
				.titulo-card{
					&:before{
						width: 12px;
					}
				}
			}
		}

		.titulo-card{
			color: #fff;
			z-index: 2;
			position: absolute;
			bottom: 64px;
			width: 100%;
			padding: 0 30px 0 20px;

			span{
				opacity: 0;
				transition: all 0.3s ease-out;
			}
		}
		.botao{
			color: #fff;
			position: absolute;
			bottom: -25px;
			right: 15px;
			z-index: 3;
		}

		&:hover{
			.titulo-card{
				span{
					opacity: 1;
				}
			}
			.botao{
				bottom: 15px;
			}
		}
	}
	@include media-breakpoint-down(md) {
		.img{
			margin-bottom: 5px;
		}
		.titulo-card{
			color: #000;
		}
		.botao{
			color: #000;
			text-align: right;
			margin-top: 5px;
		}
	}
}
// .card-padrao

.card-lista + .card-lista{
	margin-top: 20px;
	padding-top: 20px;
	border-top: 1px solid #ddd;
}

.card-lista{
	color: #414143;
	position: relative;
	overflow: hidden;

	&.tipo-dois{
		.img{
			position: relative;

			&:before{
				content: "\f144";
				z-index: 1;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				font-size: 50px;
				line-height: 1;
				color: #fff;
				font-family: 'Font Awesome 5 Free';
			    font-weight: 900;
			}
		}
	}

	.link{
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		width: 100%;
		height: 100%;
	}

	.img{
		position: relative;

		.cubo-cat{
			position: absolute;
			top: 10px;
			left: 10px;
			z-index: 1;
		}
	}

	.content{
		.nome{
			font-size: 17px;
			line-height: 19px;
			font-family: $mts-bold;
		}
		.texto{
			font-size: 14px;
			line-height: 16px;
			font-family: $mts-medium;
			margin: 5px 0;
		}
		.data-hora{
			font-size: 13px;
			line-height: 15px;
		}
	}

	@include media-breakpoint-only(xl) {
		.img{
			width: 255px;
			min-width: 255px;
		}
	}
	@include media-breakpoint-only(lg) {
		.img{
			width: 210px;
			min-width: 210px;
		}
	}
	@include media-breakpoint-only(md) {
		.img{
			width: 180px;
			min-width: 180px;
		}
	}
	@include media-breakpoint-up(md) {
		display: flex;
		align-items: center;

		.img{
			margin-right: 20px;
		}
	}
	@include media-breakpoint-down(sm) {
		width: 100%;
		max-width: 510px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;

		.img{
			margin-bottom: 10px;
		}
	}
}
// .card-lista

.card-eventos{
	font-size: 14px;
	line-height: 16px;
	color: #414143;
	margin-bottom: 30px;

	.content{
		border-left: 1px solid #ddd;
		border-right: 1px solid #ddd;
		padding: 15px 5px;

		.tipo{
			font-size: 15px;
			font-family: $mts-bold;
			line-height: 17px;
			color: #000;
			position: relative;
			display: flex;

			&:before{
				content: '';
				position: relative;
				display: block;
				width: 48px;
				height: 6px;
				background-color: #000;
				margin: auto 5px auto 0;
			}
		}
		.nome{
			font-size: 14px;
			line-height: 16px;
			font-family: $mts-bold;
			margin: 5px 0;
		}
	}
	.data{
		font-size: 15px;
		line-height: 17px;
		color: $vermelho;
		font-family: $mts-bold;
		padding: 10px 5px;
		border: 1px solid #ddd;
	}
}
// .card-eventos

.card-qualificacao{
	margin-bottom: 30px;

	.nome{
		font-size: 14px;
		color: #414143;
		font-family: $mts-medium;
		line-height: 18px;
		margin-top: 10px;
	}

	.img{
		position: relative;

		.box-header{
			position: absolute;
			top: 10px;
			left: 0;
			z-index: 1;
			background-color: #ededed;
			display: flex;
			align-items: center;
			padding: 10px 10px 10px 0;
			line-height: 1;

			.cubo-cat{
				margin-right: 10px;
			}
			span{
				font-size: 14px;
				text-transform: uppercase;
				color: #000;
				font-family: $mts-medium;
			}
		}
	}
}
// .card-qualificacao
