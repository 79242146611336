.btn-pill{
	border-radius: 2rem;
}
.btn-padrao{
	width: 100%;
	max-width: 280px;
	height: 54px;
	border-radius: 10px;
	color: #fff;
	font-family: $mts-medium;
	font-size: 13px;
	line-height: 1;
	padding: 5px;
	background-color: #000;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	transition: all 0.2s linear;
	text-align: center;

	i.fas{
		margin-left: 10px;
		opacity: 0;
		position: relative;
		left: -20px;
		transition: all 0.2s linear;
		font-size: 16px;
	}

	&:hover{
		color: #fff;

		i.fas{
			left: 0;
			opacity: 1;
		}
	}
}