.paginacao{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	list-style-type: none;
	margin-bottom: 0;
	padding-left: 0;
	margin-top: 40px;

	.active a{
		background-color: #000;
		color: #fff;
		border-color: #000;
		pointer-events: none;
	}

	a{
		width: 30px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #ddd;
		color: #222;
		font-size: 12px;

		&:hover{
			background-color: #ededed;
		}
	}
}
